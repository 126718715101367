import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutlet,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import arrow from "../../img/Arrow.svg";
import menu from "../../img/Menu.svg";
import mask from "../../img/Logo Mask.svg";
import "./index.css";
import Link from "../Link";
import { EventData, events, useEvent } from "./utils";
import Space from "../Space";
import ReactPlayer from "react-player";
import Input from "../Input";
import Button from "../Button";
import { useAuth } from "../../auth";

function Menu() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [toggled, setToggled] = useState(false);

  const handleToggle = () => {
    setToggled(true);
    setTimeout(setToggled, 3000, false);
  };

  const handleLogout = () => {
    auth.signout(() => {
      navigate('/');
    });
  };

  if (toggled) {
    return <Button variant="small" onClick={handleLogout}>Logout</Button>;
  }

  return (
    <img className="EventsMenu" src={menu} onClick={handleToggle} />
  );
}

function EventCard({ id, name, location, date, cover, disabled }: EventData) {
  const content = (
    <>
      <img src={cover} className="CardCover" />
      <div className="CardContent">
        <div className="CardDetails">
          {location}, {date}
        </div>
        <h2 className="CardTitle">{name}</h2>
      </div>
    </>
  );

  if (disabled) {
    return <div className="EventCard">{content}</div>;
  }

  return (
    <Link variant="none" to={`/live/${id}`} className="EventCard">
      {content}
    </Link>
  );
}

export function ListScreen() {
  return (
    <>
      <header className="EventsHeader">
        <h1 className="EventsTitle">Gallery</h1>
        <Menu />
      </header>

      <div className="ListScreen">
        {events.map((event) => (
          <Fragment key={event.id}>
            <Space size="xl" />
            <EventCard {...event} />
          </Fragment>
        ))}
      </div>
    </>
  );
}

export function DetailScreen() {
  const event = useEvent();
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [code, setCode] = useState<string>("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const unlocked = searchParams.get('code');
    setWidth(containerRef.current?.offsetWidth);

    if (unlocked) {
      setCode(unlocked.toUpperCase());
    }
  }, []);

  const handleCode = (event: React.FormEvent<HTMLInputElement>) => {
    setCode(event.currentTarget.value.toUpperCase());
  };

  return (
    <>
      <header className="EventsHeader">
        <Link variant="none" to="/live" className="EventsBackButton">
          <img src={arrow} className="EventsArrow" />
        </Link>
        <Menu />
      </header>

      <Space size="l" />

      {event && (
        <div className="DetailScreen" ref={containerRef}>
          <div className="EventDetails">
            {event.location}, {event.date}
          </div>
          <Space size="m" />
          <h2 className="EventTitle">{event.name}</h2>
          <Space size="xxl" />
          <p className="EventDescription">{event.description}</p>
          <Space size="xxl" />

          {event.video && width && (
            <div
              className="EventPlayer"
              style={{ width, height: width / event.video.ratio }}
            >
              {!event.video.code || code === event.video.code.toUpperCase() ? (
                <ReactPlayer
                  url={event.video.url}
                  playing
                  controls
                  width={width}
                  height={width / event.video.ratio}
                />
              ) : (
                <>
                  <Input
                    className="VideoCode"
                    placeholder="enter code to watch aftermovie"
                    onChange={handleCode}
                    value={code}
                  />
                  <img className="VideoCover" src={event.video.cover} />
                </>
              )}
            </div>
          )}

          <Space size="xl" />

          {event.gallery.length > 0 && (
            <Link variant="link" to="gallery">
              view photo gallery
            </Link>
          )}

          <Space size="m" />
          <Link variant="link" to="#">
            get merch (coming soon)
          </Link>

          <Space size="xxl" />
        </div>
      )}
    </>
  );
}

export function GalleryScreen() {
  const event = useEvent();
  const [index, setIndex] = useState(-1);
  const handleClick = (index: number) => setIndex(index);

  return (
    <>
      <header className="EventsHeader">
        <Link
          variant="none"
          to=".."
          relative="path"
          className="EventsBackButton"
        >
          <img src={arrow} className="EventsArrow" />
        </Link>
        <Menu />
      </header>

      <Space size="l" />

      {event && (
        <>
          <Gallery images={event.gallery} enableImageSelection={false} onClick={handleClick} />
          <Lightbox
            slides={event.gallery}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
          />
        </>
      )}
    </>
  );
}

export function StoreScreen() {
  return <div className="StoreScreen">Store</div>;
}

export default function EventsPage() {
  const location = useLocation();
  const nodeRef = useRef(null);
  const currentOutlef = useOutlet();

  return (
    <div className="EventsPage" data-pathname={location.pathname}>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={400}
          classNames="EventsContent"
          unmountOnExit
        >
          {() => (
            <main ref={nodeRef} className="EventsContent">
              {currentOutlef}
            </main>
          )}
        </CSSTransition>
      </SwitchTransition>

      <footer className="EventsFooter">
        <Link to="/live" variant="none">
          <img src={mask} className="EventsLogo" />
        </Link>
      </footer>
    </div>
  );
}
