import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "../Layout";
import LoginPage, {
  LoginScreen,
  RegisterScreen,
  SplashScreen,
} from "../LoginPage";
import EventsPage, {
  ListScreen,
  DetailScreen,
  GalleryScreen,
  StoreScreen,
} from "../EventsPage";
import { AuthProvider, RequireAuth } from "../../auth";
import InvitePage from "../InvitePage";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<LoginPage />}>
            <Route index element={<SplashScreen />} />
            <Route path="login" element={<LoginScreen />} />
            <Route path="register" element={<RegisterScreen />} />
          </Route>

          <Route
            path="/live"
            element={
              <RequireAuth>
                <EventsPage />
              </RequireAuth>
            }
          >
            <Route index element={<ListScreen />} />
            <Route path=":event" element={<DetailScreen />} />
            <Route path=":event/gallery" element={<GalleryScreen />} />
            <Route path=":event/store" element={<StoreScreen />} />
          </Route>

          <Route path="/invite/:code?" element={<InvitePage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
